import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';

const IndexPage = () => {
  return (
    <Layout pageTitle="Overview">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <ul>
            <li>To cultivate, grow, produce, harvest raise or deal in agriculture produce as agriculturists, farmers or gardeners and to set up processing unit for import, export, distribute or deal in agriculture produce of all description, like fruits, vegetable, seeds and herbal products and trade in all the products required for cultivation, harvesting, production and developments of seeds, vegetable, fruits and herbal items.</li>
            <li>To cultivate, grow, produce or deal in agriculture produce and to carry on the business of agriculturists, farmers, gardeners, dairymen, dairy farmers, and to acquire, hold, buy or acquire freehold or leasehold agriculture land, farm, garden or any other, property and to act as growers of corn, hay, straw, seeds or animal feed and to deal in live-stock and deal in such business as may be required by farmers and dealers of agriculture produce.</li>
            <li>To carry on the business of planters, growers and cultivators of seeds, vegetable, fruits and herbs and to cultivate, grow, produce or deal in vegetable, fruits and herbs and to undertake the activities for its processing, preservation or storage with the installation for plant, machinery, cold storage, air conditioning, refrigeration and other equipments and to provide consulting and support services.</li>
            <li>The company is currently manufacturing and trading into various Agro Commodities as mentioned on the Home Page.</li>
          </ul>
        </div>
        <div className="col-12">
          <div className="row text-center">
            <div className="col-lg-4 my-2">
              <a className="feature-link" href="/agri-commodity/#main" data-sal="zoom-in">
                <StaticImage
                  width={300}
                  height={302}
                  src="../images/home/agri-commodities.png"
                  alt="Agri Commodities"
                />
                <span className="feature-link-text">Agri Commodities</span>
              </a>
            </div>
            <div className="col-lg-4 my-2">
              <a className="feature-link" href="/investors-corner/#main" data-sal="zoom-in" data-sal-delay="100">
                <StaticImage width={300} height={302} src="../images/home/investors.png" alt="Investors" />
                <span className="feature-link-text">Investors</span>
              </a>
            </div>
            <div className="col-lg-4 my-2">
              <a className="feature-link" href="/warehousing/#main" data-sal="zoom-in" data-sal-delay="200">
                <StaticImage width={300} height={302} src="../images/home/warehousing.png" alt="Warehousing" />
                <span className="feature-link-text">Warehousing</span>
              </a>
            </div>
          </div>
        </div>
        <h1 data-sal="slide-up">Agri Commodity</h1>
        <div className="row justify-content-center text-center">
          <div className="col-lg-2">
            <a href="/agri-commodity/#cereals" data-sal="fade-in">
              <StaticImage
                width={130}
                height={130}
                src="../images/icons/cereals-and-pulses.png"
                alt="Cereals and pulses"
              />
              <br />
              <h4 className="h4">Cereals and pulses</h4>
            </a>
          </div>
          <div className="col-lg-2">
            <a href="/agri-commodity/#oil-and-seeds" data-sal="fade-in" data-sal-delay="100">
              <StaticImage width={130} height={130} src="../images/icons/oil-and-seeds.png" alt="Oil and seeds" />
              <br />
              <h4 className="h4">Oil and seeds</h4>
            </a>
          </div>
          <div className="col-lg-2">
            <a href="/agri-commodity/#fibres" data-sal="fade-in" data-sal-delay="200">
              <StaticImage width={130} height={130} src="../images/icons/fibres.png" alt="Fibres" />
              <br />
              <h4 className="h4">Fibres</h4>
            </a>
          </div>
          <div className="col-lg-2">
            <a href="/agri-commodity/#guar-complex" data-sal="fade-in" data-sal-delay="300">
              <StaticImage width={130} height={130} src="../images/icons/guar-complex.png" alt="Guar Complex" />
              <br />
              <h4 className="h4">Guar Complex</h4>
            </a>
          </div>

          <div className="col-lg-2">
            <a href="/agri-commodity/#spices" data-sal="fade-in" data-sal-delay="400">
              <StaticImage width={130} height={130} src="../images/icons/spices.png" alt="Spices" />
              <br />
              <h4 className="h4">Spices</h4>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
